// 补0
export function add0(m) {
  return m < 10 ? "0" + m : m;
}
// 倒计时
export function djTime(data) {
  return add0(parseInt(data/60))+':'+add0(data%60);
}

export function formatTime(data) {
  let time = new Date(data);
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  let d = time.getDate();
  let h = time.getHours();
  let mm = time.getMinutes();
  let ss = time.getSeconds();
  return (y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm) + ":" + add0(ss));
}
//2020年02月01日24:00
export function formatDate2(data) {
  let time = new Date(data);
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  let d = time.getDate();
  let h = time.getHours();
  let mm = time.getMinutes();
  if (add0(h) + ":" + add0(mm) == '23:59') {
    return (y + "年" + m + "月" + d + "日" + " " + "24:00");
  }
  return (y + "年" + m + "月" + d + "日" + " " + add0(h) + ":" + add0(mm));
}
//2020年02月01日
export function formatDate(data) {
  let time = new Date(data);
  let y = time.getFullYear();
  let m = time.getMonth() + 1;
  let d = time.getDate();
  let h = time.getHours();
  let mm = time.getMinutes();
  let ss = time.getSeconds();
  return (y + "年" + m + "月" + d + "日" +h + "点");
}
//计算两个时间之间的时间差 多少天时分秒
export function intervalTime(startTime, endTime) {
  let stime = new Date(startTime);
  let etime = new Date(endTime);
  if(stime > etime){
    return add0(0)+':'+add0(0)
  }
  let y = etime.getFullYear() - stime.getFullYear();
  let m = etime.getMonth() + 1 - (stime.getMonth() + 1);
  let d = etime.getDate() - stime.getDate();
  let h = etime.getHours() - stime.getHours();
  let mm = etime.getMinutes() - stime.getMinutes();
  let ss = etime.getSeconds() - stime.getSeconds();
  let time = y*365*24*60*60 + m*30*24*60*60 + d*24*60*60 + h*60*60 + mm*60 + ss
  time = add0(parseInt(time/60))+':'+add0(time%60)
  return time;
}
